// React
import {
  FC,
  useCallback,
  useEffect,
  useState
} from 'react';
import { Helmet } from 'react-helmet-async';
import { isUndefined } from 'lodash';
import toast from 'react-hot-toast';

// Material UI
import {
  Card,
  Box
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';

// Custom component and types
import UserDetailsState from 'src/types/userDetailsState';
import UserSettingsForm from 'src/components/UserSettingsForm';
import { User } from 'src/types/gateway';

// Store
import type { RootState } from 'src/store';
import { useSelector, useDispatch } from 'src/store';
import {
  setUser,
  setUpdatedDate,
  setUninitialisedUserType
} from 'src/slices/userDetails';

// Hooks and Utils
import useAuth from 'src/hooks/useAuth';
import pageTitle from 'src/utils/pageHelper';
import useMounted from 'src/hooks/useMounted';
import logger from 'src/logging/logger';
import { appConfig } from 'src/config/config';
import { useTranslation } from 'react-i18next';
import { getLogMsg, getVerboseLogMsg } from 'src/utils/loggingUtils';
import { getSessionEmail } from 'src/utils/authUtils';
import { geti18nLocale } from 'src/utils/localeHelper';
import UserActivityType, { activityTypes } from 'src/types/gateway/userActivityType';

// Data
import { gatewayService } from 'src/services/gatewayService';

const UserRegistration: FC = () => {
  // State variables
  const [userSettingsFormOpen, setUserSettingsFormOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const componentName = 'UserRegistration';

  // Selectors
  const userDetailsState = useSelector((state: RootState): UserDetailsState => state.userDetails);

  /// Constants
  const { UserInformationSaved, UnableToSaveInformation } = appConfig.i18n.messages;

  // Hooks and utils
  const mounted = useMounted();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { t: translate, i18n } = useTranslation();

  const init = useCallback(async () => {
    const { uninitialisedUserType, gatewayUserDetails: gatewayUser } = userDetailsState;
    logger.verbose('UserRegistration component init called');
    if (mounted.current) {
      logger.verbose('UserRegistration component mounted');
      logger.verbose(getVerboseLogMsg(componentName, 'init', 'uninitialisedUserType'), uninitialisedUserType);
      logger.verbose(getVerboseLogMsg(componentName, 'init', 'gatewayUser'), gatewayUser);
      try {
        setLoading(true);
        if (uninitialisedUserType === 'Corporate' || uninitialisedUserType === 'Station' || !isUndefined(gatewayUser)) {
          setUserSettingsFormOpen(true);
          logger.verbose('UserRegistration component - setting user settings form open');
        }
      } catch (err) {
        gatewayService.logErrorActivity(err);
        logger.error(getLogMsg('init', err));
      } finally {
        setLoading(false);
      }
    }
  }, [mounted]);

  useEffect(() => {
    init();
  }, [init]);

  // Evetn Handlers
  const handleSaveUserInformation = async (firstName: string, lastName: string, city: string, language: string, timezone: string, defaultStation: string) => {
    if (!user || !user.username) return;
    if (userDetailsState.uninitialisedUserType === 'Initialised') return;
    const email = await getSessionEmail();
    try {
      setLoading(true);
      let initializedUser: User;
      switch (userDetailsState.uninitialisedUserType) {
        case 'Corporate':
          initializedUser = await gatewayService.saveUserSettings(user.username, firstName, lastName, email, city, language, timezone, defaultStation);
          // Check if user has STN email address
          if (!(initializedUser.email.startsWith('stn') && initializedUser.email.endsWith('@parklandusa.com'))) {
            await gatewayService.toggleAdmin(email); // set the corporate user to admin by default
            initializedUser.is_admin = true;
            logger.verbose('handleSaveUserSettings - User Created as Admin');
            break;
          } else {
            logger.verbose('Station is STN email address:');
          }
          break;
        case 'Station':
          initializedUser = await gatewayService.updateUser(firstName, lastName, email, city, language, timezone, defaultStation);
          break;
        default:
          initializedUser = await gatewayService.updateUser(firstName, lastName, email, city, language, timezone, defaultStation);
      }
      setUser(initializedUser);
      setUninitialisedUserType('Initialised');
      logger.verbose(getVerboseLogMsg(componentName, 'handleSaveUserSettings', 'uninitialisedUserType'), initializedUser.DATA);

      // Set user language
      if (initializedUser.language) {
        const userSelected18nLanguage = geti18nLocale(initializedUser.language);
        if (userSelected18nLanguage !== i18n.language) i18n.changeLanguage(userSelected18nLanguage);
      }
      // END Set user language

      setUserSettingsFormOpen(false);
      dispatch(setUpdatedDate());
      await gatewayService.logActivity(activityTypes.initialsettings as UserActivityType);
      toast.success(translate(UserInformationSaved).toString());
      logger.verbose('handleSaveUserInformation - lookup value of initializedUser', initializedUser);
    } catch (err) {
      logger.error(getLogMsg('handleSaveUserInformation', err));
      toast.error(`${translate(UnableToSaveInformation)}: ${err}`);
    } finally {
      logger.verbose('handleSaveUserInformation - back to Home Page');
      window.location.href = '/';
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          { pageTitle(appConfig.i18n.pageTitles.overview) }
        </title>
      </Helmet>
      <Modal
        open={userSettingsFormOpen}
        onClose={() => setUserSettingsFormOpen(false)}
        aria-labelledby="user-settings-model"
        aria-describedby="update-user-settings"
      >
        <Fade in={userSettingsFormOpen}>
          <Card
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              justifyContent: 'center',
              left: 0,
              p: 3,
              position: 'fixed',
              top: 0,
              width: '100%',
              zIndex: 2000,
              backgroundColor: 'background.paper'
            }}
          >
            <UserSettingsForm
              isInitialSave
              onSubmitChanges={handleSaveUserInformation}
            />
          </Card>
        </Fade>
      </Modal>
      {loading
      && (
        <Box
          sx={{
            display: 'flex',
            ml: '50%',
            mt: 20
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
    </>
  );
};

export default UserRegistration;
