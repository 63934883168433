import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import AuthGuard from 'src/components/AuthGuard';
import AdminGuard from 'src/components/AdminGuard';
import TMGuard from 'src/components/TMGuard';
import DashboardLayout from 'src/components/dashboard/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import { appConfig } from 'src/config/config';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const {
  whoreadwhatarticles,
  quialuquelsarticles,
  whoacknowledgedwhatarticles,
  quiareconnuquelsarticles,
  dealerCommissionReport,
  userActivityReport,
  fuelRunoutReport,
  downgradeRunoutReport,
  fuelPriceReport,
  aldReport
} = appConfig.routes;

// Dashboard pages
const Profile = Loadable(lazy(() => import('src/pages/dashboard/Profile')));
const Overview = Loadable(lazy(() => import('src/pages/dashboard/Overview')));
const Favourites = Loadable(lazy(() => import('src/pages/dashboard/Favourites')));

// Admin Pages
const ManageUsers = Loadable(lazy(() => import('src/pages/admin/ManageUsers')));
const BulkUpdateContent = Loadable(lazy(() => import('src/pages/admin/BulkUpdateContent')));

// TM Pages
const InternalDocumentationDashboard = Loadable(lazy(() => import('src/pages/InternalDocumentationDashboard')));

// Articles
const Article = Loadable(lazy(() => import('src/pages/Article')));
const ArticleCategory = Loadable(lazy(() => import('src/pages/ArticleCategory')));
const ArticlesWithTag = Loadable(lazy(() => import('src/pages/ArticlesWithTag')));

// other pages
const MysteryShopperDashboard = Loadable(lazy(() => import('src/pages/MysteryShopperDashboard')));
const Links = Loadable(lazy(() => import('src/pages/Links')));
const Reports = Loadable(lazy(() => import('src/pages/Reports')));
const Planograms = Loadable(lazy(() => import('src/pages/Planograms')));
const CarWashDashboard = Loadable(lazy(() => import('src/pages/CarWashDashboard')));
const ManageTeam = Loadable(lazy(() => import('src/pages/ManageTeam')));
const SubmitServiceTicket = Loadable(lazy(() => import('src/pages/SubmitServiceTicket')));
const OTRHub = Loadable(lazy(() => import('src/pages/OTRHub')));
// Forms page will come later
// const Forms = Loadable(lazy(() => import('src/pages/Forms')));

// Help Pages
const FAQ = Loadable(lazy(() => import('src/pages/help/FAQ')));
const TermsAndConditions = Loadable(lazy(() => import('src/pages/help/TermsAndConditions')));
const Help = Loadable(lazy(() => import('src/pages/help/Help')));

// Reports
const WhoReadWhatArticles = Loadable(lazy(() => import('src/pages/reports/WhoReadWhatArticles')));
const WhoAcknowledgedWhatArticles = Loadable(lazy(() => import('src/pages/reports/WhoAcknowledgedWhatArticles')));
const DealerCommissionReport = Loadable(lazy(() => import('src/pages/reports/DealerCommission')));
const UserActivityReport = Loadable(lazy(() => import('src/pages/reports/UserActivityReport')));
const FuelRunoutReport = Loadable(lazy(() => import('src/pages/reports/FuelRunoutReport')));
const DowngradeRunoutReport = Loadable(lazy(() => import('src/pages/reports/DowngradeRunoutReport')));
const FuelPriceReport = Loadable(lazy(() => import('src/pages/reports/FuelPriceReport')));
const ALDReport = Loadable(lazy(() => import('src/pages/reports/ALDReport')));

// ContactDirectory page will come later
// const ContactDirectory = Loadable(lazy(() => import('src/pages/help/ContactDirectory')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('src/pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('src/pages/NotFound')));
const ServerError = Loadable(lazy(() => import('src/pages/ServerError')));
const ArticleUnauthorized = Loadable(lazy(() => import('src/pages/ArticleUnauthorized')));

// Development pages
const Test = Loadable(lazy(() => import('src/pages/tools/Test')));
const Debug = Loadable(lazy(() => import('src/pages/tools/Debug')));
const ToolsStations = Loadable(lazy(() => import('src/pages/tools/Stations')));
const ToolsUsers = Loadable(lazy(() => import('src/pages/tools/Users')));

// PIC AWS Pages
const Surveys = Loadable(lazy(() => import('src/pages/Surveys')));

const routes: PartialRouteObject[] = [
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: '/profile',
        element: <Profile />
      },
      {
        path: 'favourites',
        element: <Favourites />
      },
      {
        path: 'article',
        element: <Article />
      },
      {
        path: 'articles/:category',
        element: <ArticleCategory />
      },
      {
        path: 'articles/tag/:tag',
        element: <ArticlesWithTag />
      },
      // {
      //   path: '/forms',
      //   element: <Forms />
      // },
      {
        path: '/links',
        element: <Links />
      },
      {
        path: '/planograms',
        element: <Planograms />
      },
      {
        path: '/carwashdashboard',
        element: <CarWashDashboard />
      },
      {
        path: '/otrhub',
        element: <OTRHub />
      },
      {
        path: '/manageteam',
        element: <ManageTeam />
      },
      {
        path: '/submitserviceticket',
        element: <SubmitServiceTicket />
      },
      {
        path: '/mysteryshopper',
        element: <MysteryShopperDashboard />
      },
      {
        path: '/contact',
        element: <Planograms />
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: 'articleunauthorized',
        element: <ArticleUnauthorized />
      },
      {
        path: '*',
        element: <NotFound />
      },
      {
        path: '/test',
        element: <Test />
      },
      {
        path: '/debug',
        element: <Debug />
      },
      {
        path: '/toolsusers',
        element: <ToolsUsers />
      },
      {
        path: '/toolsstations',
        element: <ToolsStations />
      }
    ]
  },
  {
    path: 'help',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Help />
      },
      {
        path: '/faq',
        element: <FAQ />
      },
      {
        path: '/termsandconditions',
        element: <TermsAndConditions />
      },
      // {
      //   path: '/contactdirectory',
      //   element: <ContactDirectory />
      // }
    ]
  },
  {
    path: 'reports',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Reports />
      },
      {
        path: fuelRunoutReport,
        element: <FuelRunoutReport />
      },
      {
        path: downgradeRunoutReport,
        element: <DowngradeRunoutReport />
      },
      {
        path: aldReport,
        element: <ALDReport />
      },
      {
        path: whoreadwhatarticles,
        element: <WhoReadWhatArticles />
      },
      {
        path: quialuquelsarticles,
        element: <WhoReadWhatArticles />
      },
      {
        path: whoacknowledgedwhatarticles,
        element: <WhoAcknowledgedWhatArticles />
      },
      {
        path: quiareconnuquelsarticles,
        element: <WhoAcknowledgedWhatArticles />
      },
      {
        path: dealerCommissionReport,
        element: <DealerCommissionReport />
      },
      {
        path: userActivityReport,
        element: <UserActivityReport />
      },
      {
        path: fuelPriceReport,
        element: <FuelPriceReport />
      }
    ]
  },
  {
    path: 'admin',
    element: (
      <AdminGuard>
        <DashboardLayout />
      </AdminGuard>
    ),
    children: [
      {
        path: '/users',
        element: <ManageUsers />
      },
      {
        path: '/bulkupdatecontent',
        element: <BulkUpdateContent />
      },
    ]
  },
  {
    path: 'internaldocumentation',
    element: (
      <TMGuard>
        <DashboardLayout />
      </TMGuard>
    ),
    children: [
      {
        path: '/',
        element: <InternalDocumentationDashboard />
      }
    ]
  },
  {
    path: 'Surveys',
    element: (
      <TMGuard>
        <DashboardLayout />
      </TMGuard>
    ),
    children: [
      {
        path: '/',
        element: <Surveys />
      }
    ]
  }
];

export default routes;
