// React
import { useEffect, useState, useCallback } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';

// Material UI
import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import LinkIcon from '@material-ui/icons/Link';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import CategoryIcon from '@material-ui/icons/Category';
// import DashboardIcon from '@material-ui/icons/Dashboard';
// import PaymentsIcon from '@material-ui/icons/Payments';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
// import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
// import HearingIcon from '@mui/icons-material/Hearing';
import ArticleIcon from '@material-ui/icons/Article';

// Custom components and types
import Logo from 'src/components/Logo';
import NavItem from 'src/components/NavItem';
import Scrollbar from 'src/components/Scrollbar';
import DashboardSidebarProps from 'src/types/props/dashboardSidebarProps';
import ImageLinkInNewWindow from '../controls/ImageLinkInNewWindow';
import Brand from 'src/types/brand';
import GatewayStation from 'src/types/gateway/gatewayStation';
import GatewayUser, {
  isAdminOrTMUser,
  userCanManageTeam
} from 'src/types/gateway/gatewayUser';

// Services
import { contentfulService } from 'src/services/contentfulService';
import { gatewayService } from 'src/services/gatewayService';

// Store
import type { RootState } from 'src/store';
import { useSelector } from 'src/store';

// Hooks and utils
import { useTranslation } from 'react-i18next';
import useMounted from 'src/hooks/useMounted';
import logger from 'src/logging/logger';
import _ from 'lodash';
import { getLogMsg } from 'src/utils/loggingUtils';
import { isDevOrTest, isDevelopment } from 'src/utils/environmentUtils';
import { appConfig } from 'src/config/config';

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  // State variables
  const [categories, setCategories] = useState<string[]>([]);
  const [userHasRDOCStations, setUserHasRDOCStations] = useState<boolean>(false);
  const { onSidebarClose, openSidebar } = props;

  // Hooks and utils
  const location = useLocation();
  const mounted = useMounted();
  const { t: translate, i18n } = useTranslation();

  // Store
  const selectedbrand = useSelector((state: RootState): Brand | null => state.userDetails.selectedBrand);
  const selectedStation = useSelector((state: RootState): GatewayStation | null => state.userDetails.selectedStation);
  const gatewayUser = useSelector((state: RootState): GatewayUser | null => state.userDetails.gatewayUserDetails);

  // Contants
  const {
    CategoriesNavSection,
    ManageNavSection,
    HomePage,
    LinksPage,
    internalDocumentationPage,
    PlanogramsPage,
    ReportsPage,
    manageUsers,
    // carwash,
    manageTeam,
    // onTheRun,
  } = appConfig.i18n.navbar;

  // Data
  const init = useCallback(async () => {
    try {
      if (mounted.current) {
        const articleCategoriesList = await contentfulService.getArticleCategories(i18n.language);
        if (mounted.current) setCategories(_.sortBy(articleCategoriesList));
        const session = await Auth.currentSession();
        const { email } = session.getIdToken().payload;
        const doesUserHaveRDOCStations = await gatewayService.doesUserHaveRDOCStations(email);
        logger.verbose(getLogMsg('Dashboard Sidebar - init doesUserHaveRDOCStations', doesUserHaveRDOCStations));
        if (mounted.current) setUserHasRDOCStations(doesUserHaveRDOCStations);
      }
    } catch (err) {
      logger.error(getLogMsg('Dashboard Sidebar - init', err));
    }
  }, [mounted]);

  useEffect(() => {
    init();
  }, [init, i18n.language]);

  useEffect(() => {
    if (openSidebar && onSidebarClose) {
      onSidebarClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: 'background.paper'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'flex',
              xs: 'flex'
            },
            justifyContent: 'center',
            py: 1
          }}
        >
          <RouterLink to="/">
            <Logo showSmall />
          </RouterLink>
          {selectedbrand && selectedbrand.brand
          && (
            <>
              <Divider
                orientation="vertical"
                flexItem
                variant="middle"
                style={{ paddingLeft: '10px' }}
              />
              <ImageLinkInNewWindow
                url={selectedbrand.websiteUrl}
                imgAlt={selectedbrand.brand}
                imgSrc={selectedbrand.logoLarge}
              />
            </>
          )}
        </Box>
        <Divider />
        <Box
          sx={{
            display: {
              lg: 'flex',
              xs: 'flex'
            },
            justifyContent: 'center'
          }}
        >
          <NavItem
            depth={0}
            title={HomePage}
            translateKey={translate(HomePage)}
            icon={<HomeIcon />}
            path="/"
          />
        </Box>
        <Divider />
        <Box
          sx={{
            display: {
              lg: 'flex',
              xs: 'flex'
            },
            justifyContent: 'center'
          }}
        >
          <NavItem
            depth={0}
            open={false}
            title={translate(ManageNavSection)}
            translateKey={translate(ManageNavSection)}
            icon={<SupervisorAccountIcon />}
          >
            <List>
              {isAdminOrTMUser(gatewayUser)
              && (
                <ListItem
                  disableGutters
                  sx={{
                    display: 'flex',
                    py: 0
                  }}
                >
                  <Button
                    component={RouterLink}
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 'fontWeightMedium',
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      pl: '22px',
                      pr: '3px',
                      py: '12px',
                      textTransform: 'none',
                      width: '100%'
                    }}
                    variant="text"
                    to="../reports"
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      {translate(ReportsPage)}
                    </Box>
                  </Button>
                </ListItem>
              )}
              {userCanManageTeam(gatewayUser, selectedStation)
              && (
                <ListItem
                  disableGutters
                  sx={{
                    display: 'flex',
                    py: 0
                  }}
                >
                  <Button
                    component={RouterLink}
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 'fontWeightMedium',
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      pl: '22px',
                      pr: '3px',
                      py: '12px',
                      textTransform: 'none',
                      width: '100%'
                    }}
                    variant="text"
                    to="../manageteam"
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      {translate(manageTeam)}
                    </Box>
                  </Button>
                </ListItem>
              )}
              {/* {isAdminUser(gatewayUser)
              && (
                <ListItem
                  disableGutters
                  sx={{
                    display: 'flex',
                    py: 0
                  }}
                >
                  <Button
                    component={RouterLink}
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 'fontWeightMedium',
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      pl: '22px',
                      pr: '3px',
                      py: '12px',
                      textTransform: 'none',
                      width: '100%'
                    }}
                    variant="text"
                    to="../admin/bulkupdatecontent"
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      {translate(bulkUpdate)}
                    </Box>
                  </Button>
                </ListItem>
              )} */}
              {gatewayUser.is_super
              && (
                <ListItem
                  disableGutters
                  sx={{
                    display: 'flex',
                    py: 0
                  }}
                >
                  <Button
                    component={RouterLink}
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 'fontWeightMedium',
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      pl: '22px',
                      pr: '3px',
                      py: '12px',
                      textTransform: 'none',
                      width: '100%'
                    }}
                    variant="text"
                    to="../admin/users"
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      {translate(manageUsers)}
                    </Box>
                  </Button>
                </ListItem>
              )}
            </List>
          </NavItem>
        </Box>
        <Divider />
        <Box
          sx={{
            display: {
              lg: 'flex',
              xs: 'flex'
            },
            justifyContent: 'center'
          }}
        >
          <NavItem
            depth={0}
            open={false}
            title={translate(CategoriesNavSection)}
            translateKey={translate(CategoriesNavSection)}
            icon={<CategoryIcon />}
          >
            <List>
              {categories && categories.map((category: string) => (
                <ListItem
                  key={category}
                  disableGutters
                  sx={{
                    display: 'flex',
                    py: 0
                  }}
                >
                  <Button
                    component={RouterLink}
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 'fontWeightMedium',
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      pl: '22px',
                      pr: '3px',
                      py: '12px',
                      textTransform: 'none',
                      width: '100%'
                    }}
                    variant="text"
                    to={`../articles/${encodeURIComponent(category)}`}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      {category}
                    </Box>
                  </Button>
                </ListItem>
              ))}
            </List>
          </NavItem>
        </Box>
        {gatewayUser && ((gatewayUser.is_admin || gatewayUser.is_super) || userHasRDOCStations) // only show commission report menu item for admins, super admins and TM/ROM users with RDOC assigned stations
        && (
          <Box
            sx={{
              display: {
                lg: 'flex',
                xs: 'flex'
              },
              justifyContent: 'center'
            }}
          >
            {/* <NavItem
              depth={0}
              title={translate(dealerCommissionReportPage)}
              translateKey={translate(dealerCommissionReportPage)}
              icon={<PaymentsIcon />}
              path="../reports/dealercommissionreport"
            /> */}
          </Box>
        )}
        <Box
          sx={{
            display: {
              lg: 'flex',
              xs: 'flex'
            },
            justifyContent: 'center'
          }}
        >
          {/* <NavItem
            depth={0}
            title={translate(fuelPriceReportPage)}
            translateKey={translate(fuelPriceReportPage)}
            icon={<LocalGasStationIcon />}
            path="../reports/fuelpricereport"
          /> */}
        </Box>
        <Box //
          sx={{
            display: {
              lg: 'flex',
              xs: 'flex'
            },
            justifyContent: 'center'
          }}
        >
          {/* <NavItem
            depth={0}
            title={translate(surveysPage)}
            translateKey={translate(surveysPage)}
            icon={<HearingIcon />}
            path="../surveys"
          /> */}
        </Box>
        <Box
          sx={{
            display: {
              lg: 'flex',
              xs: 'flex'
            },
            justifyContent: 'center'
          }}
        >
          <NavItem
            depth={0}
            title={translate(PlanogramsPage)}
            translateKey={translate(PlanogramsPage)}
            icon={<AssessmentIcon />}
            path="../planograms"
          />
        </Box>
        <Box
          sx={{
            display: {
              lg: 'flex',
              xs: 'flex'
            },
            justifyContent: 'center'
          }}
        >
          {/* <NavItem
            depth={0}
            open={false}
            title={translate(dashboard)}
            translateKey={translate(dashboard)}
            icon={<DashboardIcon />}
          >
            <List>
              {selectedStation && selectedStation.car_wash_flag
              && (
                <ListItem
                  key={carwash}
                  disableGutters
                  sx={{
                    display: 'flex',
                    py: 0
                  }}
                >
                  <Button
                    component={RouterLink}
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 'fontWeightMedium',
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      pl: '22px',
                      pr: '3px',
                      py: '12px',
                      textTransform: 'none',
                      width: '100%'
                    }}
                    variant="text"
                    to="../carwashdashboard"
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      {translate(carwash)}
                    </Box>
                  </Button>
                </ListItem>
              )} */}
          {/* <ListItem
                key={mysteryShopper}
                disableGutters
                sx={{
                  display: 'flex',
                  py: 0
                }}
              >
                <Button
                  component={RouterLink}
                  sx={{
                    color: 'text.secondary',
                    fontWeight: 'fontWeightMedium',
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                    pl: '22px',
                    pr: '3px',
                    py: '12px',
                    textTransform: 'none',
                    width: '100%'
                  }}
                  variant="text"
                  to="../mysteryshopper"
                >
                  <Box sx={{ flexGrow: 1 }}>
                    {translate(mysteryShopper)}
                  </Box>
                </Button>
              </ListItem> */}
          {/* {selectedStation && selectedStation.is_otr_dealer
              && (
                <ListItem
                  key={onTheRun}
                  disableGutters
                  sx={{
                    display: 'flex',
                    py: 0
                  }}
                >
                  <Button
                    component={RouterLink}
                    sx={{
                      color: 'text.secondary',
                      fontWeight: 'fontWeightMedium',
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                      pl: '22px',
                      pr: '3px',
                      py: '12px',
                      textTransform: 'none',
                      width: '100%'
                    }}
                    variant="text"
                    to="../otrhub"
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      {translate(onTheRun)}
                    </Box>
                  </Button>
                </ListItem>
              )}
            </List>
          </NavItem>
        </Box>
        <Box
          sx={{
            display: {
              lg: 'flex',
              xs: 'flex'
            },
            justifyContent: 'center'
          }}
        > */}
          <NavItem
            depth={0}
            title={translate(internalDocumentationPage)}
            translateKey={translate(internalDocumentationPage)}
            icon={<ArticleIcon />}
            path="../internaldocumentation"
          />
        </Box>
        <Box
          sx={{
            display: {
              lg: 'flex',
              xs: 'flex'
            },
            justifyContent: 'center'
          }}
        >
          <NavItem
            depth={0}
            title={translate(LinksPage)}
            translateKey={translate(LinksPage)}
            icon={<LinkIcon />}
            path="../links"
          />
        </Box>
        {isDevOrTest()
        && (
          <>
            <Divider />
            <Box
              sx={{
                display: {
                  lg: 'flex',
                  xs: 'flex'
                },
                justifyContent: 'center'
              }}
            >
              <NavItem
                depth={0}
                open={false}
                title="Tools"
                translateKey="Tools"
                icon={<DeveloperBoardIcon />}
              >
                <List>
                  <ListItem
                    disableGutters
                    sx={{
                      display: 'flex',
                      py: 0
                    }}
                  >
                    <Button
                      component={RouterLink}
                      sx={{
                        color: 'text.secondary',
                        fontWeight: 'fontWeightMedium',
                        justifyContent: 'flex-start',
                        textAlign: 'left',
                        pl: '22px',
                        pr: '3px',
                        py: '12px',
                        textTransform: 'none',
                        width: '100%'
                      }}
                      variant="text"
                      to="../debug"
                    >
                      <Box sx={{ flexGrow: 1 }}>
                        Debug
                      </Box>
                    </Button>
                  </ListItem>
                  <ListItem
                    disableGutters
                    sx={{
                      display: 'flex',
                      py: 0
                    }}
                  >
                    <Button
                      component={RouterLink}
                      sx={{
                        color: 'text.secondary',
                        fontWeight: 'fontWeightMedium',
                        justifyContent: 'flex-start',
                        textAlign: 'left',
                        pl: '22px',
                        pr: '3px',
                        py: '12px',
                        textTransform: 'none',
                        width: '100%'
                      }}
                      variant="text"
                      to="../toolsusers"
                    >
                      <Box sx={{ flexGrow: 1 }}>
                        Users
                      </Box>
                    </Button>
                  </ListItem>
                  <ListItem
                    disableGutters
                    sx={{
                      display: 'flex',
                      py: 0
                    }}
                  >
                    <Button
                      component={RouterLink}
                      sx={{
                        color: 'text.secondary',
                        fontWeight: 'fontWeightMedium',
                        justifyContent: 'flex-start',
                        textAlign: 'left',
                        pl: '22px',
                        pr: '3px',
                        py: '12px',
                        textTransform: 'none',
                        width: '100%'
                      }}
                      variant="text"
                      to="../toolsstations"
                    >
                      <Box sx={{ flexGrow: 1 }}>
                        Stations
                      </Box>
                    </Button>
                  </ListItem>
                  {isDevelopment()
                  && (
                    <ListItem
                      disableGutters
                      sx={{
                        display: 'flex',
                        py: 0
                      }}
                    >
                      <Button
                        component={RouterLink}
                        sx={{
                          color: 'text.secondary',
                          fontWeight: 'fontWeightMedium',
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                          pl: '22px',
                          pr: '3px',
                          py: '12px',
                          textTransform: 'none',
                          width: '100%'
                        }}
                        variant="text"
                        to="../test"
                      >
                        <Box sx={{ flexGrow: 1 }}>
                          Test
                        </Box>
                      </Button>
                    </ListItem>
                  )}
                </List>
              </NavItem>
            </Box>
          </>
        )}
      </Scrollbar>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      onClose={onSidebarClose}
      open={openSidebar}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onSidebarClose: PropTypes.func,
  openSidebar: PropTypes.bool
};

export default DashboardSidebar;
