import { appConfig } from 'src/config/config';

// Constants
const { contentfulEnglish, contentfulSpanish, i18nEnglish, i18nSpanish } = appConfig.i18n.languages;

/**
 * converts the i18n language to contentful language/locale
 * @author Kevin Parkinson
 * @param {string} i18nLanguage i18n language en | fr
*/
export const getContentfulLanguageFromi18nLanguage = (i18nLanguage: String) => {
  let contentfulLanguage;
  switch (i18nLanguage) {
    case i18nEnglish:
      contentfulLanguage = contentfulEnglish;
      break;
    case i18nSpanish:
      contentfulLanguage = contentfulSpanish;
      break;
    default:
      contentfulLanguage = contentfulEnglish;
  }
  return contentfulLanguage;
};

/**
 * converts the contentful language/locale to i18n language
 * @author Kevin Parkinson
 * @param {string} contentfuLanguage contentfuLanguage en-US | fr-CA
*/
export const get18nLanguageFromContentfulLanguage = (contentfuLanguage: String) => {
  let i18nLanguage;
  switch (contentfuLanguage) {
    case contentfulEnglish:
      i18nLanguage = i18nEnglish;
      break;
    case contentfulSpanish:
      i18nLanguage = i18nSpanish;
      break;
    default:
      i18nLanguage = i18nEnglish;
  }
  return i18nLanguage;
};
