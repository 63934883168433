// React
import { useRef, useState } from 'react';
import type { FC } from 'react';

// AWS Amplify
import { Auth } from 'aws-amplify';

// material UI
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

// Services
import { gatewayService } from 'src/services/gatewayService';

// Store
import { useDispatch } from 'src/store/index';
import { saveUserLanguage } from 'src/slices/userDetails';

// Hooks and utils
import { appConfig } from 'src/config/config';
import { getContentfulLanguageFromi18nLanguage } from 'src/utils/languageUtils';
import store from 'src/store/localStore';
import {
  updateUserActivityDateTime
} from 'src/utils/cookieUtils';

// Constants
const languageOptions = {
  en: {
    icon: '/static/icons/US-Flag-icon 32.png',
    label: 'English (US)'
  },
  es: {
    icon: '/static/icons/ES-Spain-Flag-icon.png',
    label: 'Spanish (ES)'
  }
};

const LanguagePopover: FC = () => {
  // Hooks and utils
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { i18n } = useTranslation();
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  // State variables
  const [open, setOpen] = useState<boolean>(false);

  // Event Handlers
  const handleOpen = (): void => {
    setOpen(true);
    // record user activity
    updateUserActivityDateTime();
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChangeLanguage = async (language: string) => {
    store.set(appConfig.localStorage.userSelectedLanguage, language);
    i18n.changeLanguage(language);
    const session = await Auth.currentSession();
    const { email } = session.getIdToken().payload;
    const contentfulLang = getContentfulLanguageFromi18nLanguage(language);
    await gatewayService.updateUserLanguage(email, contentfulLang);
    dispatch(saveUserLanguage(contentfulLang));
    setOpen(false);
    // record user activity
    updateUserActivityDateTime();
  };

  return (
    <>
      <Tooltip title={translate(appConfig.i18n.actions.language)}>
        <IconButton
          onClick={handleOpen}
          ref={anchorRef}
        >
          <Box
            sx={{
              display: 'flex',
              height: 20,
              width: 20,
              '& img': {
                width: '100%'
              }
            }}
          >
            <img
              alt={languageOptions[i18n.language].label}
              src={languageOptions[i18n.language].icon}
            />
          </Box>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        {Object.keys(languageOptions).map((language) => (
          <MenuItem
            onClick={() => handleChangeLanguage(language)}
            key={language}
          >
            <ListItemIcon>
              <Box
                sx={{
                  display: 'flex',
                  height: 20,
                  width: 20,
                  '& img': {
                    width: '100%'
                  }
                }}
              >
                <img
                  alt={languageOptions[language].label}
                  src={languageOptions[language].icon}
                />
              </Box>
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="GrayText"
                  variant="subtitle2"
                >
                  {languageOptions[language].label}
                </Typography>
              )}
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;
