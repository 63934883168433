// React
import { useRef, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Material UI
import {
  Badge,
  Box,
  Card,
  CardHeader,
  IconButton,
  Link,
  ListItemText,
  MenuItem,
  Modal,
  Popover,
  Tooltip,
  Typography
} from '@material-ui/core';
import HelpOutLine from '@material-ui/icons/HelpOutline';
import { makeStyles, Theme } from '@material-ui/core/styles';

// Hooks and utils
import { useTranslation } from 'react-i18next';
import { appConfig } from 'src/config/config';
import {
  updateUserActivityDateTime
} from 'src/utils/cookieUtils';

const getModalStyle = () => {
  const pos = 50;
  return {
    top: '200px',
    left: `${pos}%`,
    transform: `translate(-${pos}%, -${pos}%)`,
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  }
}));

const HelpPopover: FC = () => {
  // State variables
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [aboutModalOpen, setAboutModalOpen] = useState<boolean>(false);
  const [modalStyle] = useState(getModalStyle);

  // Constants
  const { t: translate } = useTranslation();
  const {
    about,
    help,
    faq,
    submitServiceTicket,
    termsAndConditions
    // contactDirectory
  } = appConfig.i18n.pageTitles;
  const {
    help: helpRoute,
    faq: faqRoute,
    submitServiceTicket: submitServiceTicketRoute,
    termsAndConditions: termsAndConditionsRoute
    // contactDirectory
  } = appConfig.routes;
  const { appVersion } = appConfig.i18n.formFields;
  const classes = useStyles();

  const handleOpen = (): void => {
    setOpen(true);
    // record user activity
    updateUserActivityDateTime();
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleAboutClicked = (): void => {
    setAboutModalOpen(true);
    // record user activity
    updateUserActivityDateTime();
  };

  const handleAboutModalClose = (): void => {
    setAboutModalOpen(false);
  };

  return (
    <>
      <Tooltip title={translate(appConfig.i18n.actions.help)}>
        <IconButton
          color="primary"
          ref={anchorRef}
          onClick={handleOpen}
        >
          <Badge color="error">
            <HelpOutLine fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper'
          }
        }}
      >
        <Box>
          <MenuItem
            component={RouterLink}
            to={helpRoute}
          >
            <ListItemText
              primary={(
                <Typography
                  color="GrayText"
                  variant="subtitle2"
                >
                  {translate(help)}
                </Typography>
              )}
            />
          </MenuItem>
          {appConfig.appSettings.enableNewAndUnfinishedFeatures
          && (
            <MenuItem
              component={RouterLink}
              to={submitServiceTicketRoute}
            >
              <ListItemText
                primary={(
                  <Typography
                    color="GrayText"
                    variant="subtitle2"
                  >
                    {translate(submitServiceTicket)}
                  </Typography>
                )}
              />
            </MenuItem>
          )}
          <MenuItem
            component={RouterLink}
            to={faqRoute}
          >
            <ListItemText
              primary={(
                <Typography
                  color="GrayText"
                  variant="subtitle2"
                >
                  {translate(faq)}
                </Typography>
              )}
            />
          </MenuItem>
          <MenuItem
            component={RouterLink}
            to={termsAndConditionsRoute}
          >
            <ListItemText
              primary={(
                <Typography
                  color="GrayText"
                  variant="subtitle2"
                >
                  {translate(termsAndConditions)}
                </Typography>
              )}
            />
          </MenuItem>
          <MenuItem>
            <Link
              sx={{
                color: 'background.paper',
                fontWeight: 'fontWeightMedium',
                justifyContent: 'flex-start',
                textAlign: 'left',
                width: '100%',
                textDecoration: 'none',
                cursor: 'pointer',
                ':hover': {
                  textDecoration: 'none'
                }
              }}
              onClick={handleAboutClicked}
            >
              <ListItemText
                primary={(
                  <Typography
                    color="GrayText"
                    variant="subtitle2"
                  >
                    {translate(about)}
                  </Typography>
                 )}
              />
            </Link>
          </MenuItem>
          {/* Contact Directory page will come later
          <MenuItem
            component={RouterLink}
            to="/help/contactdirectory"
          >
            <ListItemText
              primary={(
                <Typography
                  color="GrayText"
                  variant="subtitle2"
                >
                  {translate(contactDirectory)}
                </Typography>
              )}
            />
          </MenuItem> */}
        </Box>
      </Popover>
      <Modal
        open={aboutModalOpen}
        onClose={handleAboutModalClose}
      >
        <div
          style={modalStyle}
          className={classes.paper}
        >
          <Card
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              left: 0,
              p: 3,
              position: 'fixed',
              top: 0,
              width: '100%',
              zIndex: 2000,
              backgroundColor: 'background.paper'
            }}
          >
            <CardHeader
              title={(
                <Typography
                  color="GrayText"
                  variant="overline"
                  fontWeight="700"
                  fontSize="large"
                >
                  {translate(about)}
                </Typography>
              )}
            />
            <Box>
              <Typography
                color="GrayText"
                variant="caption"
              >
                {`${translate(appVersion)}: ${appConfig.appVersion}`}
              </Typography>
            </Box>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default HelpPopover;
