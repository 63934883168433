import { appConfig } from 'src/config/config';

const { i18nEnglish, i18nSpanish, contentfulEnglish, contentfulSpanish } = appConfig.i18n.languages;

/**
 * TODO: describe function
 * @author Kevin Parkinson
 * @param locale (string) i18n language en | fr
*/
export const getContentfulLocale = (locale: String) => {
  let contentfulLocale;
  switch (locale) {
    case i18nEnglish:
      contentfulLocale = contentfulEnglish;
      break;
    case i18nSpanish:
      contentfulLocale = contentfulSpanish;
      break;
    default:
      contentfulLocale = contentfulEnglish;
  }
  return contentfulLocale;
};

/**
 * TODO: describe function
 * @author Kevin Parkinson
 * @param locale (string) i18n language en | fr
*/
export const geti18nLocale = (locale: String) => {
  let contentfulLocale;
  switch (locale) {
    case contentfulEnglish:
      contentfulLocale = i18nEnglish;
      break;
    case contentfulSpanish:
      contentfulLocale = i18nSpanish;
      break;
    default:
      contentfulLocale = i18nEnglish;
  }
  return contentfulLocale;
};
