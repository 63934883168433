import GatewayStationSummary from 'src/types/gateway/gatewayStationSummary';
import GatewayStation from 'src/types/gateway/gatewayStation';

interface GatewayUser {
  DATA: string;
  PK: string;
  SK: string;
  okta_id: string;
  first_name: string;
  last_name: string;
  email: string;
  city: string;
  language: string;
  timezone: string;
  default_station: string;
  bookmarks: string[];
  stations: GatewayStationSummary[];
  mdm_stations: GatewayStationSummary[];
  is_super: boolean;
  is_admin: boolean;
  is_tm: boolean;
}

export const getEmptyUser = (): GatewayUser => ({
  DATA: '',
  PK: '',
  SK: '',
  okta_id: '',
  email: '',
  first_name: '',
  last_name: '',
  language: '',
  timezone: '',
  city: '',
  default_station: '',
  bookmarks: [],
  stations: [],
  mdm_stations: [],
  is_super: false,
  is_admin: false,
  is_tm: false
});

export const getOktaID = (user: GatewayUser) => {
  if (user.okta_id) return user.okta_id;
  return user.PK.replace('USR#', '');
};

export const isRegularUser = (user: GatewayUser) => !user.is_super && !user.is_admin && !user.is_tm;

export const isAdminOrTMUser = (user: GatewayUser) => user.is_super || user.is_admin || user.is_tm;

export const isAdminUser = (user: GatewayUser) => user.is_super || user.is_admin;

export const isTMUser = (user: GatewayUser) => user.is_tm;

export const userFullName = (user: GatewayUser) => {
  if (!user) return '';
  if (!user.first_name || !user.last_name) return '';
  return `${user.first_name} ${user.last_name}`;
};

export const allAssignedStations = (user: GatewayUser): GatewayStationSummary[] => user.mdm_stations.concat(user.stations);

export const allAssignedStationIDs = (user: GatewayUser): string[] => user.mdm_stations.concat(user.stations).map((s) => s.site_id);

export const userHasStationsAssigned = (user: GatewayUser): boolean => {
  if (!user) return false;
  if (!user.mdm_stations && !user.stations) return false;
  const allStations = allAssignedStations(user);
  return allStations && allStations.length > 0;
};

export const userCanManageTeam = (user: GatewayUser, station?: GatewayStation): boolean => {
  if (isAdminUser(user)) return false;
  if (isTMUser(user)) return true;
  if (!user) return false;
  if (!station || !station.site_id) return false;
  if (!user.mdm_stations && !user.stations) return false;
  const allStations = allAssignedStationIDs(user);
  return allStations.includes(station.site_id) && (isTMUser(user) || isRegularUser(user));
};

export const isEmptyUser = (user: GatewayUser): boolean => (!user || !user.email || user === getEmptyUser());

export default GatewayUser;
