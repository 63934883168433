/* eslint-disable react/no-array-index-key */
// React
import { useState } from 'react';
import type { FC, KeyboardEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Material UI
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Drawer,
  IconButton,
  InputAdornment,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
// import LocalOfferIcon from '@material-ui/icons/LocalOffer';
// import { makeStyles } from '@material-ui/core/styles';

// Store
import type { RootState } from 'src/store';
import { useSelector } from 'src/store';

// Services
import { gatewayService } from 'src/services/gatewayService';

// Custom Types
import SearchIcon from 'src/icons/Search';
import XIcon from 'src/icons/X';
import GatewayStation, { classOfTradeCode, getBrandName } from 'src/types/gateway/gatewayStation';
import GatewayUser, { isAdminOrTMUser } from 'src/types/gateway/gatewayUser';
import NoContentBanner from '../NoContentBanner';
// import ArticleChip from 'src/components/controls/ArticleChip';
import CloudSearchResults, { ArticleCloudSearchResult, PlanogramCloudSearchResult } from 'src/types/cloudSearchResults';
import ErrorMessage from 'src/components/ErrorMessage';

// Hooks and Utils
import { useTranslation } from 'react-i18next';
import { appConfig } from 'src/config/config';
import { getArticleRoute } from 'src/utils/routeUtils';
import NoItemsMessage from 'src/components/NoItemsMessage';
import useMounted from 'src/hooks/useMounted';
// import _ from 'lodash';
import { shouldIApplyFilters } from 'src/utils/contentFilterUtils';
import { updateUserActivityDateTime } from 'src/utils/cookieUtils';
import UserActivityType, { activityTypes } from 'src/types/gateway/userActivityType';
import logger from 'src/logging/logger';
import { getLogMsg } from 'src/utils/loggingUtils';

const ContentSearch: FC = () => {
  // State Variables
  const [value, setValue] = useState<string>('');
  const [searchResults, setSearchResults] = useState<CloudSearchResults>();
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [articlePage, setArticlePage] = useState<number>(0);
  const [rowsPerPage] = useState<number>(5);
  const [planogramPage, setPlanogramPage] = useState<number>(0);
  const [showContent, setShowContent] = useState<boolean>(false);
  const [resultsHaveErrors, setResultsHaveErrors] = useState<boolean>(false);

  // Hooks and utils
  const { t: translate, i18n } = useTranslation();
  const mounted = useMounted();

  // Constants
  const componentName = 'Content Search';
  const { lookingForSomething, searchInstructions } = appConfig.i18n.messages;
  const { search: searchAction, clear, cancel } = appConfig.i18n.actions;
  const { articles, planograms } = appConfig.i18n.sectionHeaders;
  const {
    weCouldNotFindArticlesFor,
    weCouldNotFindPlanogramsFor,
    resultsForSearch,
    couldNotLoadSearchResults
  } = appConfig.i18n.messages;
  // const useStyles = makeStyles((theme) => ({
  //   root: {
  //     display: 'flex',
  //     justifyContent: 'left',
  //     flexWrap: 'wrap',
  //     listStyle: 'none',
  //     padding: theme.spacing(0.5),
  //     margin: 0,
  //   }
  // }));
  // const classes = useStyles();

  // Selectors
  const brands = useSelector((state: RootState): string[] => state.contentfulLookups.brands);
  const classOfTrade = useSelector((state: RootState): string[] => state.contentfulLookups.classOfTrade);
  const regions = useSelector((state: RootState): string[] => state.contentfulLookups.regions);
  const selectedStation = useSelector((state: RootState): GatewayStation | null => state.userDetails.selectedStation);
  const gatewayUser = useSelector((state: RootState): GatewayUser | null => state.userDetails.gatewayUserDetails);

  // Event Handlers
  const handleOpen = (): void => {
    const applyFilters = shouldIApplyFilters(selectedStation, brands, classOfTrade, regions);
    const showTheContent = applyFilters || (!applyFilters && isAdminOrTMUser(gatewayUser));
    if (showTheContent) setShowContent(true);
    else {
      setShowContent(false);
      setShowResults(false);
    }
    if (mounted.current) setOpen(true);
    // record user activity
    updateUserActivityDateTime();
  };

  const handleClose = (): void => {
    if (mounted.current) {
      setValue('');
      setShowResults(false);
      setOpen(false);
    }
    // record user activity
    updateUserActivityDateTime();
  };

  const handleArticlesChangePage = (event: unknown, newPage: number) => {
    if (mounted.current) setArticlePage(newPage);
    // record user activity
    updateUserActivityDateTime();
  };

  const handlePlanogramsChangePage = (event: unknown, newPage: number) => {
    if (mounted.current) setPlanogramPage(newPage);
    // record user activity
    updateUserActivityDateTime();
  };

  const search = async (): Promise<void> => {
    try {
      if (mounted.current) setArticlePage(0);
      if (mounted.current) setPlanogramPage(0);
      if (mounted.current) setShowResults(false);
      if (mounted.current) setIsLoading(true);
      const applyFilters = shouldIApplyFilters(selectedStation, brands, classOfTrade, regions);
      let results: CloudSearchResults;
      const { site_id: siteID, brand, province, is_otr_dealer: isOTR } = selectedStation;
      if (applyFilters) {
        results = await gatewayService.cloudSearchFiltered(value,
          siteID,
          getBrandName(brand),
          classOfTradeCode(selectedStation),
          province,
          isOTR,
          i18n.language);
      } else {
        results = await gatewayService.cloudSearch(value, i18n.language);
      }
      // articleSearchResults = _.sortBy(articleSearchResults, ['title'], ['asc']);
      if (mounted.current) setSearchResults(results);
      if (mounted.current) setIsLoading(false);
      if (mounted.current) setShowResults(true);
      gatewayService.logActivity(activityTypes.search as UserActivityType, { searchTerm: value });
      setResultsHaveErrors(false);
      // record user activity
      updateUserActivityDateTime();
    } catch (err) {
      if (mounted.current) {
        setIsLoading(false);
        setResultsHaveErrors(true);
        setShowResults(false);
      }
      gatewayService.logErrorActivity(err);
      logger.error(getLogMsg(`${componentName} - search`, err));
    }
  };

  const handleClick = (): void => {
    search();
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.code.toUpperCase() === 'ENTER' || event.code.toUpperCase() === 'NUMPADENTER') {
      search();
    }
  };

  const handleResultClicked = (): void => {
    setValue('');
    setSearchResults(null);
    setIsLoading(false);
    setOpen(false);
    setShowResults(false);
  };

  const handleCancel = () => {
    setSearchResults(null);
    setIsLoading(false);
    setShowResults(false);
  };

  const handleClear = () => {
    setValue('');
    setSearchResults(null);
    setIsLoading(false);
    setShowResults(false);
  };

  return (
    <>
      <Tooltip title={translate(appConfig.i18n.actions.search)}>
        <IconButton
          color="primary"
          onClick={handleOpen}
        >
          <SearchIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="top"
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: '100%',
            backgroundColor: 'background.paper'
          },
        }}
        variant="temporary"
      >
        {!showContent
        && (
          <NoContentBanner />
        )}
        {showContent
        && (
          <>
            <Box sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <IconButton onClick={handleClose}>
                  <XIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ p: 3 }}>
              <Container maxWidth="md">
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  <Typography
                    color="GrayText"
                    variant="h6"
                  >
                    {translate(lookingForSomething)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    pb: 2
                  }}
                >
                  <Typography
                    color="GrayText"
                    variant="caption"
                  >
                    {translate(searchInstructions)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      )
                    }}
                    onChange={(event): void => setValue(event.target.value)}
                    onKeyUp={handleKeyUp}
                    placeholder={`${translate(appConfig.i18n.actions.search)}...`}
                    value={value}
                  />
                  <Button
                    color="primary"
                    onClick={handleClick}
                    size="large"
                    sx={{ ml: 2 }}
                    variant="outlined"
                  >
                    {translate(searchAction)}
                  </Button>
                  <Button
                    color="primary"
                    disabled={!showResults}
                    onClick={handleClear}
                    size="large"
                    sx={{ ml: 2 }}
                    variant="outlined"
                  >
                    {translate(clear)}
                  </Button>
                  <Button
                    color="primary"
                    onClick={handleCancel}
                    disabled={!isLoading}
                    size="large"
                    sx={{ ml: 2 }}
                    variant="outlined"
                  >
                    {translate(cancel)}
                  </Button>
                </Box>
                {isLoading
                && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      p: 1
                    }}
                  >
                    <CircularProgress color="primary" />
                  </Box>
                )}
                {!isLoading && resultsHaveErrors
                && (
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      py: 1
                    }}
                  >
                    <ErrorMessage errorMessageKey={couldNotLoadSearchResults} />
                  </Box>
                )}
                {!isLoading && showResults
                && (
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      py: 1
                    }}
                  >
                    <Typography
                      color="GrayText"
                      variant="h6"
                    >
                      {translate(articles)}
                    </Typography>
                  </Box>
                )}
                {!isLoading && showResults && searchResults && searchResults.articles && searchResults.articles.length === 0
                && (
                  <NoItemsMessage errorMessageKey={`${translate(weCouldNotFindArticlesFor)} '${value}'`} />
                )}
                {!isLoading && showResults && searchResults && searchResults.articles && searchResults.articles.length > 0
                && (
                  <>
                    <Box sx={{ mt: 3 }}>
                      {`${searchResults.articles.length} ${translate(resultsForSearch)} '${value}'`}
                    </Box>
                    <Box sx={{ mt: 3 }}>
                      <TableContainer
                        sx={{
                          backgroundColor: 'background.paper'
                        }}
                      >
                        <Table
                          stickyHeader
                          aria-label="Article Search Results"
                        >
                          <TableBody>
                            {searchResults.articles
                              .slice(articlePage * rowsPerPage, articlePage * rowsPerPage + rowsPerPage)
                              .map((result: ArticleCloudSearchResult) => (
                                <TableRow
                                  key={result.id}
                                  sx={{ mb: 2 }}
                                >
                                  <TableCell>
                                    <RouterLink
                                      to={getArticleRoute(result.id)}
                                      onClick={handleResultClicked}
                                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                                    >
                                      <Typography color="primary">
                                        {`${result.title} - (${result.category}) `}
                                      </Typography>
                                    </RouterLink>
                                    {/* <Typography
                                      color="textPrimary"
                                      variant="caption"
                                    >
                                      {result.summary}
                                    </Typography>
                                    <ul className={classes.root}>
                                      {result.tags && result.tags.map((tag) => (
                                        <li key={tag}>
                                          <ArticleChip
                                            icon={<LocalOfferIcon />}
                                            label={tag ?? 'no tag/pas de tag'}
                                          />
                                        </li>
                                      ))}
                                    </ul> */}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5]}
                        component="div"
                        count={searchResults.articles.length}
                        rowsPerPage={rowsPerPage}
                        page={articlePage}
                        onPageChange={handleArticlesChangePage}
                      />
                    </Box>
                  </>
                )}
                {showResults
                && (
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      py: 1
                    }}
                  >
                    <Typography
                      color="GrayText"
                      variant="h6"
                    >
                      {translate(planograms)}
                    </Typography>
                  </Box>
                )}
                {showResults && searchResults && searchResults.planograms && searchResults.planograms.length === 0
                && (
                  <NoItemsMessage errorMessageKey={`${translate(weCouldNotFindPlanogramsFor)} '${value}'`} />
                )}
                {!isLoading && showResults && searchResults.planograms && searchResults.planograms.length > 0
                && (
                  <>
                    <Box sx={{ mt: 3 }}>
                      {`${searchResults.planograms.length} ${translate(resultsForSearch)} '${value}'`}
                    </Box>
                    <Box sx={{ mt: 3 }}>
                      <TableContainer
                        sx={{
                          backgroundColor: 'background.paper'
                        }}
                      >
                        <Table
                          stickyHeader
                          aria-label="Planograms"
                        >
                          <TableBody>
                            {searchResults.planograms
                              .slice(planogramPage * rowsPerPage, planogramPage * rowsPerPage + rowsPerPage)
                              .map((result: PlanogramCloudSearchResult) => (
                                <TableRow
                                  key={result.id}
                                  sx={{ mb: 2 }}
                                >
                                  <TableCell>
                                    <Typography color="primary">
                                      {`${result.title} ${result.shelfSize && result.shelfSize.toUpperCase() !== 'NA' ? result.shelfSize : ''} (${result.category}) `}
                                      <Tooltip title={translate(appConfig.i18n.actions.download)}>
                                        <IconButton
                                          href={result.url}
                                          target="_blank"
                                          download
                                        >
                                          <CloudDownload color="primary" />
                                        </IconButton>
                                      </Tooltip>
                                    </Typography>
                                    {/* <ul className={classes.root}>
                                      {result.tags && result.tags.map((tag) => (
                                        <li key={tag}>
                                          <ArticleChip
                                            icon={<LocalOfferIcon />}
                                            label={tag ?? 'no tag/pas de tag'}
                                          />
                                        </li>
                                      ))}
                                    </ul> */}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[5]}
                        component="div"
                        count={searchResults.planograms.length}
                        rowsPerPage={rowsPerPage}
                        page={planogramPage}
                        onPageChange={handlePlanogramsChangePage}
                      />
                    </Box>
                  </>
                )}
              </Container>
            </Box>
          </>
        )}
      </Drawer>
    </>
  );
};

export default ContentSearch;
