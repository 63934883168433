// React
import type { FC } from 'react';
import { useState, useEffect, useCallback } from 'react';

// Hooks and utils
import { useTranslation } from 'react-i18next';
import logger from 'src/logging/logger';
import _ from 'lodash';

// Material UI
import {
  Box,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { getLogMsg } from '../../utils/loggingUtils';
import { gatewayService } from 'src/services/gatewayService';

// Custom types and components
import FuelPrice from 'src/types/fuelPrice';
import GatewayStation from 'src/types/gateway/gatewayStation';

// Store
import type { RootState } from 'src/store';
import { useSelector } from 'src/store';

const FuelPriceWidget: FC = () => {
  // State variables
  const [dislayFuelPrice, setDisplayFuelPrice] = useState<FuelPrice[]>();
  const selectedStation = useSelector((state: RootState): GatewayStation | null => state.userDetails.selectedStation);

  // hooks and utils
  const {
    i18n,
  } = useTranslation();

  const init = useCallback(async () => {
    try {
      let fuelPrices = await gatewayService.getFuelPrices(selectedStation.site_id, i18n.language);
      fuelPrices = _.uniqBy(_.orderBy(fuelPrices, ['DisplayOrder'], ['asc']), 'DisplayOrder');
      setDisplayFuelPrice(fuelPrices);
    } catch (err) {
      logger.error(getLogMsg('init', err));
    }
  }, []);

  useEffect(() => {
    init();
  }, [init, i18n.language]);

  const handleProductType = (type) => {
    switch (Object.values(type)[0]) {
      case 1:
        return '#bbdefb';
        break;
      case 5:
        return '#00e676';
        break;
      default:
        return '#fff';
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
      >
        {dislayFuelPrice
          && dislayFuelPrice.map(
            ({
              DisplayOrder,
              SiteID,
              ProductDesc,
              SSPrice,
            }: FuelPrice) => (
              <Grid
                item
                xs={2}
                sx={{
                  p: 0.5,
                }}
                key={`${DisplayOrder}-${ProductDesc}-${SiteID}`}
              >
                <Paper
                  sx={{ backgroundColor: handleProductType({ DisplayOrder }) }}
                >
                  <Grid
                    item
                    xs
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Typography
                      gutterBottom
                      variant="caption"
                      component="div"
                    >
                      {ProductDesc}
                    </Typography>
                    <Typography
                      variant="h6"
                      color="text.secondary"
                    >
                      {SSPrice}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
            )
          )}
      </Grid>
    </Box>
  );
};

export default FuelPriceWidget;
